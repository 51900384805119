<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
      >
        <template #cell(level)="data">
          <span v-if="data.item.level == 'kantorjaga'">
            Kantor Jaga
          </span>
          <span v-else-if="data.item.level == 'ulp'">
            ULP
          </span>
          <span v-else-if="data.item.level == 'pusat'">
            Pusat
          </span>
          <span v-else-if="data.item.level == 'region'">
            Region
          </span>
          <span v-else-if="data.item.level == 'area'">
            Area Layanan
          </span>
          <span v-else>
            Project
          </span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV2.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar Jabatan',
      dataurl:'/job-title',
      baseroute:'jabatan',
      fields:[
        { key: 'name', label: 'Jenis Jabatan', sortable: true},
        { key: 'level', label: 'Tingkat/Level', sortable: true},
      ],
    }
  },
}
</script>